/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 // You can delete this file if you're not using it

// exports.onInitialClientRender = () => {
//   console.log('Hey, you made it to my site! Cool!');
// };

require('prismjs/themes/prism-okaidia.min.css');
